<template>
  <router-view/>
</template>


<style>
.content {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
