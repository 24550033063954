import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueCookies from 'vue-cookies';
import VueFeather from 'vue-feather';


const Globalvariable = {
	data () {
		return {
			//base_url: 'http://192.168.0.104:5000/',
			base_url: 'https://api.teradoengineering.com/',
			axios: axios
		}
	}
}

const app = createApp(App).use(router, VueCookies);
app.component(VueFeather.name, VueFeather)
app.mixin(Globalvariable);
app.mount('#app');
