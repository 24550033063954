import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'website',
    component: () => import('../views/website/WebsiteView.vue')
  },
  {
    path: '/termsandconditions',
    name: 'terms',
    component: () => import('../views/website/TermsView.vue')
  },
  {
    path: '/privacyandpolicy',
    name: 'privacy',
    component: () => import('../views/website/PrivacyView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth/RegisterView.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/app/DashboardView.vue')
  },
  /*{
    path: '/app/leads',
    name: 'leads',
    component: () => import('../views/app/LeadsView.vue')
  },*/
  {
    path: '/app/insights',
    name: 'insights',
    component: () => import('../views/app/InsightsView.vue')
  },
  {
    path: '/app/integrations',
    name: 'integration',
    //component: () => import('../views/app/IntegrationView.vue')
    component: () => import('../views/app/ConnectView.vue')
  },
  {
    path: '/app/connect',
    name: 'connect',
    component: () => import('../views/app/ConnectView.vue')
  },
  {
    path: '/app/webhooks',
    name: 'webhooks',
    component: () => import('../views/app/WebhookView.vue')
  },
  {
    path: '/app/accounts',
    name: 'accounts',
    component: () => import('../views/app/AccountView.vue')
  },
  {
    path: '/app/settings',
    name: 'setttings',
    component: () => import('../views/app/AccountView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
